import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MySlider from "../components/slider"
import Forces from "../components/forces"
import Actuality from "../components/actuality"
import AboutUs from "../components/about-us"

// import Background from "../assets/background.svg"

import { graphql } from 'gatsby'
//import './slider-animations.css';

// function IndexPage({ data }) {

//   return (

//     <Layout>
//       <SEO title="Home" />
//       {/* <Background className={"bg-top-index"} /> */}
//       <div className="wrapper">
//         <MySlider data={data} />
//         <Forces data={data} />
//         <Actuality data={data} />
//         <AboutUs data={data} />


//       </div>
//     </Layout >
//   )
// }
function IndexPage({}) {

  return (

    <Layout>
      <SEO title="Home" />
      {/* <Background className={"bg-top-index"} /> */}
      <div className="wrapper">
         <MySlider />
        <Forces />
        <Actuality />
        <AboutUs />


      </div>
    </Layout >
  )
}
// export const query = graphql`
//   query {
    
//     allStrapiActuality {
//     nodes {
//       Date
//       Title
//       Text
//       children {
//         id
//       }
//       Media{
//         childImageSharp {
//               fluid {
//                 ...GatsbyImageSharpFluid
//               }
//             }
        
//       }
//     }
//   } 
//     allStrapiProduits(sort: {order: ASC, fields: id}) {
//     nodes {
//       Title
//       Text
//       children {
//         id
//       }
//       Media{
//         childImageSharp {
//               fluid {
//                 ...GatsbyImageSharpFluid
//               }
//             }
        
//       }
//     }
//   }
//   allStrapiForces {
//     nodes {
//       Title
//       Text
//     }
//   }
//   strapiAboutUs {
//     Title
//     Text
//   }
//   allStrapiFaq {
//     nodes {
//       Question
//       Response
//     }
//   }
//   }
// `
export default IndexPage
