import React from "react"

// function Forces({ data }) {
//     return (
//         <div className="forces-container">
//             <h2>Our Goals</h2>
//             <hr />
//             <div className="forces-item-container">
//                 {data.allStrapiForces.nodes.map((node, index) => (

//                     <div className="forces-item" key={index}>
//                         <h3>{node.Title}</h3>
//                         <p>{node.Text}</p>

//                     </div>

//                 ))}
//             </div>
//         </div>
//     )
// }
function Forces({}) {
    return (
        <div className="forces-container">
            <h2>Our Goals</h2>
            <hr />
            <div className="forces-item-container">
                {/* {data.allStrapiForces.nodes.map((node, index) => (

                    <div className="forces-item" key={index}>
                        <h3>{node.Title}</h3>
                        <p>{node.Text}</p>

                    </div>

                ))} */}

                    <div className="forces-item" key={1}>
                        <h3>Prevent falls</h3>
                        <p>Improvement in Tinetti score, TUG, SPPB and symmetry of steps.</p>

                    </div>
                    <div className="forces-item" key={2}>
                        <h3>Improve quality of life</h3>
                        <p>Intrinsic motivation and quality of life improved.</p>

                    </div>
                    <div className="forces-item" key={3}>
                        <h3>Increase physical activity</h3>
                        <p>Increase the number of steps taken per day by the participants</p>

                    </div>
            </div>
        </div>
    )
}

export default Forces