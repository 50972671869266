
import React from "react"

import SocialMedia from "../components/social-medias"

import GamotionSvg from "../assets/gamotion.svg"



function AboutUs({ }) {
    return (
        <div className={"aboutUs-container"}>
            <div className={"aboutUs-content"}>
                <div className={"center-icon"}><GamotionSvg style={{
                    width: "250px",
                }} /></div>
                <div className={"aboutUs-text"}>
                    <h2>follow us</h2>
                    <p></p>
                </div>
                <SocialMedia height={"50px"} />

            </div></div>
    )
}
// function AboutUs({ data }) {
//     return (
//         <div className={"aboutUs-container"}>
//             <div className={"aboutUs-content"}>
//                 <div className={"center-icon"}><GamotionSvg style={{
//                     width: "250px",
//                 }} /></div>
//                 <div className={"aboutUs-text"}>
//                     <h2>{data.strapiAboutUs.Title}</h2>
//                     <p>{data.strapiAboutUs.Text}</p>
//                 </div>
//                 <SocialMedia height={"50px"} />

//             </div></div>
//     )
// }
export default AboutUs