import React from "react"
import { Card, Button } from 'react-bootstrap'
// import Img from "gatsby-image"
import { Link } from "gatsby"

import Image from '../components/image';


// function Forces({ data }) {
//     return (
//         <div className="actuality-container">
//             <h2>News</h2>
//             <hr />
//             {data.allStrapiActuality.nodes.map((node, index) => (
//                 <Card key={index}>
//                     <Img fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
//                     <Card.Body>
//                         <Card.Title>{node.Title}</Card.Title>
//                         <Card.Text>
//                             {node.Text}
//                         </Card.Text>
//                     </Card.Body>
//                 </Card>


//             ))
//             }
//             <Button>
//                 <Link to={"/News"} className="link-no-style">

//                     See all

//             </Link>
//             </Button>


//         </div >
//     )
// }
function Forces({  }) {
    return (
        <div className="actuality-container">
            <h2>News</h2>
            <hr />
            {/* {data.allStrapiActuality.nodes.map((node, index) => (
                <Card key={index}>
                    <Img fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
                    <Card.Body>
                        <Card.Title>{node.Title}</Card.Title>
                        <Card.Text>
                            {node.Text}
                        </Card.Text>
                    </Card.Body>
                </Card>


            ))
            } */}
            <Card key={1} style={{ width: '90%' }}>
            <Image
                                    src="salonsoins.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            <Card.Body>
              <Card.Title>Salon Soins et Santé (September 30 - October 1th 2021)</Card.Title>
              <Card.Text>
              During this medical congress, you will have the opportunity to discover and to test our product. Meet us there!
              </Card.Text>
            </Card.Body>
          </Card>
      <Card key={2} style={{ width: '90%' }}>
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            <Image
                                    src="wcol.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
            <Card.Body>
              <Card.Title>WCO-IOF-ESCEO London 2021 (August 26 - 29th 2021)</Card.Title>
              <Card.Text>
              During this medical congress, you will have the opportunity to discover and to test our product. Meet us there!
              </Card.Text>
            </Card.Body>
          </Card>
      <Card key={3} style={{ width: '90%' }}>
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            <Image
                                    src="wcov.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
            <Card.Body>
              <Card.Title>WCO-IOF-ESCEO virtual congress (August 20 - 22nd 2020)</Card.Title>
              <Card.Text>
              We are proud to present our brand new product at the World's leading clinical conference on bone, joint, and muscle health where an exhibition area is dedicated to the GAMotion boarding game.
              </Card.Text>
            </Card.Body>
          </Card>
          
            <Button>
                <Link to={"/News"} className="link-no-style">

                    See all

            </Link>
            </Button>


        </div >
    )
}

export default Forces