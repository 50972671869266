import React from "react"
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import Slider from "react-slick";
import Img from "gatsby-image"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from '../components/image';

// function MySlider({ data }) {
//     var settings = {
//         lazyLoad: 'progressive',
//         arrows: false,
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         appendDots: dots => {
//             return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={50} />;
//         }
//     };
//     return (
//         <div className={"slider-container"}>
//             <Slider {...settings}>
//                 {data.allStrapiProduits.nodes.map((node, index) => (
//                     <div key={index}>
//                         <div
//                             className="slide-content"

//                         // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
//                         >
//                             <div className="inner">
//                                 <h1>{node.Title}</h1>
//                                 <p>{node.Text}</p>
//                                 {/* <div>
//                                     <button>{"See more"}</button>
//                                     <button style={{ background: "red", }}>{"Download PDF"}</button>
//                                 </div> */}
//                             </div>
//                             <div className="img">
//                                 <Img fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     )
// }
// function MySlider({ data }) {
//     var settings = {
//         lazyLoad: 'progressive',
//         arrows: false,
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         appendDots: dots => {
//             return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={50} />;
//         }
//     };
//     return (
//         <div className={"slider-container"}>
//             <Slider {...settings}>
//                 {data.allStrapiProduits.nodes.map((node, index) => (
//                     <div key={index}>
//                         <div
//                             className="slide-content"

//                         // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
//                         >
//                             <div className="inner">
//                                 <h1>{node.Title}</h1>
//                                 <p>{node.Text}</p>
//                                 {/* <div>
//                                     <button>{"See more"}</button>
//                                     <button style={{ background: "red", }}>{"Download PDF"}</button>
//                                 </div> */}
//                             </div>
//                             <div className="img">
//                                 <Img fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     )
// }

// export default MySlider

 function MySlider({ data }) {
     var settings = {
         lazyLoad: 'progressive',
         arrows: false,
         dots: true,
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1,
         appendDots: dots => {
             return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={50} />;
         }
     };
     return (
        <div className={"slider-container"}>
            <Slider {...settings}>
                
            <div key={1}>
                        <div
                            className="slide-content"

                        // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1>A giant boarding game for older people</h1>
                                <p>The  GAMotion  game  is  designed  to  improve  physical  activity  levels  of  older  people,  both  within  and  outside  of  residential  care.  It  can  be  an  important  intervention  in  any  program  targeting  fall  prevention  and  loss  of autonomy with its playful approach and its strong social component.</p>
                                
                                {/* <div>
                                    <button>{"See more"}</button>
                                    <button style={{ background: "red", }}>{"Download PDF"}</button>
                                </div> */}
                            </div>
                             <div className="img">
                             {/* <Img fluid={fluid} alt={gg} /> */}
                                 <Image
                                    src="gamotiontapis.png"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: `100%`,
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        objectfit: "contain",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
                            </div> 
                        </div>
                    </div>
                    <div key={2}>
                        <div
                            className="slide-content"

                        // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1>Various exercises</h1>
                                <p>Three of the main components of physical activity are trained :

- Balance 
- Muscle strength
- Endurance</p>
                                {/* <div>
                                    <button>{"See more"}</button>
                                    <button style={{ background: "red", }}>{"Download PDF"}</button>
                                </div> */}
                            </div>
                             <div className="img">
                                 <Image
                                    src="Equilibre.png"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: `100%`,
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
                            </div> 
                        </div>
                    </div>
                    <div key={3}>
                        <div
                            className="slide-content"

                        // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1>Three intensity levels</h1>
                                <p>The physical intensity of the exercices can be modified to suit the participants needs and physical capacity.</p>
                                {/* <div>
                                    <button>{"See more"}</button>
                                    <button style={{ background: "red", }}>{"Download PDF"}</button>
                                </div> */}
                            </div>
                             <div className="img">
                            <Image
                                    src="Force.png"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: `100%`,
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
                            </div> 
                        </div>
                    </div>
                    <div key={4}>
                        <div
                            className="slide-content"

                        // style={{ background: `url('${node.media.absolutePath}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1>Illustration</h1>
                                <p>Every exercice is shown in an easy to understand illustration.</p>
                                {/* <div>
                                    <button>{"See more"}</button>
                                    <button style={{ background: "red", }}>{"Download PDF"}</button>
                                </div> */}
                            </div>
                             <div className="img">
                             <Image
                                    src="Marche.png"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: `100%`,
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
                            </div> 
                        </div>
                    </div>
            </Slider>
        </div>
     )
 }
 export default MySlider